import React from 'react';


function Band() {
    return(
        <div class="band">
            
        </div>
    )
}

export default Band;
