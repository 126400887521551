import React from 'react';

function Hero() {
    return(
        <div id="home" className="hero">
            <div className="hero-title">
                <h1>EL ROSAL</h1>
            </div>
            <div className="hero-subtitle">
                <h2>GARDEN & NURSERY</h2>
            </div>
            <div>
                <p>808 S Marienfeld St, Midland, TX 79701</p>
                <p>&</p>
                <p>2204 NCR 1147, Midland, TX 79705</p>
            </div>
        </div>
    )
}

export default Hero;