import React from 'react';
import flower1 from '../flower1.jpeg';
import flower2 from '../flower2.png';

function About() {
    return (
        <section id="about" class="about-us">
            <div class="about-us-main">
                <h2>About Us</h2>
                <p>Welcome to El Rosal, where we've been providing beautiful and healthy plants for your home or office since 2014. With two locations in Midland, Texas, we're passionate about inspiring and educating plant lovers of all levels, and our friendly staff are always available to answer your questions and offer guidance on plant care. Thank you for choosing El Rosal - we can't wait to help you bring the beauty of the natural world into your space!</p>
            </div>
            
            <div class="about-us-cards">
                <div class="about-us-card">
                <img src={flower1} alt="flower 1" />
                <h3>El Rosal</h3>
                <p>Located at </p> 
                <p>808 S Marienfeld St, Midland, Texas 79701</p>
                </div>
                <div class="about-us-card">
                <img src={flower2} alt="flower 2" />
                <h3>El Rosal 2</h3>
                <p>Located at </p> 
                
                <p>2204 NCR 1147, Midland, Texas 79705</p>
                </div>
            </div>
    </section>
    


    );
  }
export default About;
  