import React from 'react';
import flower1 from '../flower1.jpeg';
import flower2 from '../flower2.jpeg';
import flower3 from '../flower3.jpeg';
import flower4 from '../flower4.jpeg';
import flower5 from '../flower5.jpeg';
import flower6 from '../flower6.jpeg';
import flower7 from '../flower7.jpeg';
import flower8 from '../flower8.jpeg';
import flower9 from '../flower9.jpeg';
import flower10 from '../flower10.jpeg';
import flower11 from '../flower11.jpeg';
import flower13 from '../flower13.jpeg';
import flower14 from '../flower14.jpeg';
import flower15 from '../flower15.jpeg';
import flower16 from '../flower16.jpeg';
import flower17 from '../flower17.jpeg';
import flower18 from '../flower18.jpeg';
import flower19 from '../flower19.jpeg';
import flower20 from '../flower20.jpeg';

function Gallery() {
    return (
        <div id="carouselExampleControlsNoTouching" className="carousel slide" data-bs-touch="false" data-bs-wrap="true">
        <div className="carousel-inner">
            <div className="carousel-item active">
            <img src={flower1} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower2} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower3} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower4} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower5} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower6} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower7} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower8} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower9} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower10} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower11} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower13} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower14} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower15} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower16} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower17} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower18} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower19} className="d-block w-100" alt="..."/>
            </div>
            <div className="carousel-item">
            <img src={flower20} className="d-block w-100" alt="..."/>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControlsNoTouching" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
        </div>

    )
};

export default Gallery;

