import React from 'react';
import logo from '../elrosallogo.svg';

function Navbar() {
  return (
    <nav>
      <img src={logo} alt="logo" className="logo"/>
      <ul className="nav-links">
        <li className="nav-link"><a href="#home">Home</a></li>
        <li className="nav-link"><a href="#about">About</a></li>
        <li className="nav-link"><a href="#carouselExampleControlsNoTouching">Gallery</a></li>
      </ul>
    </nav>
  );
}

export default Navbar;
