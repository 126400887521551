import './App.css';

import Navbar from './Navbar/navbar.js';
import Hero from './Hero/hero.js';
import About from './About/about.js';
import Band from './Band/band.js';
import Gallery from './Gallery/gallery.js';
import Footer from './Footer/footer.js';

function App() {
  return (
    <div>
      <Navbar />
      <Hero />
      <About />
      <Band />
      <Gallery />
      <Footer />
    </div>
  );
}

export default App;
