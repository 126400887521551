import React from 'react';


function Footer() {
    return (
        <footer class="footer">
            <div class="container">
                <p>&copy; 2023 El Rosal</p>
            </div>
        </footer>


    )

}

export default Footer;